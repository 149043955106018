// import Web3Modal, { getProviderInfo } from 'web3modal'
import { getProviderInfo } from "web3modal";

// https://docs.gnosis-safe.io/build/sdks/safe-apps/get-started
// https://docs.gnosis-safe.io/build/sdks/safe-apps/build
// https://github.com/gnosis/safe-apps-sdk/tree/master/packages/safe-apps-web3modal
import { SafeAppWeb3Modal } from "@gnosis.pm/safe-apps-web3modal";

// import Torus from "@toruslabs/torus-embed";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

// import WalletLink from 'walletlink'
import { ethers } from "ethers";
import securityKeyProvider from "./securityKey";

// import signer from '~/utils/signer'
import { getBlockchainNetwork } from "~/services/blockchain";
import { useSessionStore } from "~~/store/session";
import { useRiseIdStore } from "~~/store/riseId";
import { useWalletNotAllowedStore } from "~~/store/walletNotAllowed";

let _web3modalOptions, _network;

function isWalletOwnerOrDelegate({ wallets, riseIdOwner, delegates }) {
  if (!riseIdOwner) return true;
  if (!wallets || wallets?.length <= 0) return false;

  const walletAddress = wallets[0].address.toLowerCase();
  const riseIdOwnerAddress = riseIdOwner?.toLowerCase();
  const matchDelegateAddress = delegates?.some((e) => e.address.toLowerCase());

  return walletAddress === riseIdOwnerAddress || matchDelegateAddress;
}

export async function init() {
  const runtimeConfig = useRuntimeConfig();
  const { APP_NAME, APP_LOGO_URL } = runtimeConfig.public;
  const sessionStore = useSessionStore();
  await sessionStore.me();
  const advanced = sessionStore.advanced;
  let enabledSecKey = false;
  let wallets = sessionStore.wallets;
  if (!wallets) {
    await sessionStore.getWallet();
    wallets = sessionStore.wallets;
  }
  const riseIdStore = useRiseIdStore();
  const riseIdOwner = await riseIdStore.getOwner(sessionStore.riseId);
  const delegates = await riseIdStore.getDelegates();
  if (isWalletOwnerOrDelegate({ wallets, riseIdOwner, delegates }))
    enabledSecKey = true;
  try {
    const { rpcUrl, rpcUrls, chainId, chainName, chainIdHex, nativeCurrency } =
      (await getBlockchainNetwork()).data;
    _network = {
      rpcUrl,
      rpcUrls,
      chainId,
      chainName,
      rpc: {},
      chainIdHex,
      nativeCurrency,
    };
    _network.rpc[Number(chainId)] = rpcUrl;

    _web3modalOptions =
      advanced !== false ||
      !isWalletOwnerOrDelegate({ wallets, riseIdOwner, delegates })
        ? {
            // Disable displayed provider so user does not switch. Important because our ssystem is implied on which provider to use
            disableInjectedProvider: false, // Just for now so we can test it with MetaMask
            cacheProvider: false,
            providerOptions: {
              injected: {
                options: { rpc: rpcUrl, chainId },
              },
              // walletconnect: {
              //   package: WalletConnectProvider, // required
              //   display: {
              //     logo: "/icons/mew.png",
              //     name: "Mew Wallet",
              //     description: "Scan with your Mew Wallet app",
              //   },
              //   options: {
              //     rpc: rpcUrl, // Optional if `infuraId` is provided; otherwise it's required
              //     chainId, // Optional. It defaults to 1 if not provided
              //     qrcodeModalOptions: {
              //       desktopLinks: [],
              //     },
              //   },
              // },
              "custom-coinbase": {
                display: {
                  logo: "/icons/coinbase.png",
                  name: "Coinbase",
                  description: "Scan with your Coinbase Wallet app",
                },
                options: {
                  appName: APP_NAME, // Required
                  rpc: rpcUrl, // Optional if `infuraId` is provided; otherwise it's required
                  rpcUrls,
                  chainId, // Optional. It defaults to 1 if not provided
                  appLogoUrl: APP_LOGO_URL, // Optional. Application logo image URL. favicon is used if unspecified
                },
                package: CoinbaseWalletSDK,
                connector: async (_, options) => {
                  const { appName, rpc, chainId } = options;
                  const walletLink = new CoinbaseWalletSDK({
                    appName,
                  });
                  const provider = walletLink.makeWeb3Provider(rpc, chainId);
                  await provider.enable();
                  const connectedChainId = ethers.BigNumber.from(
                    provider.chainId
                  );
                  if (connectedChainId.toNumber() !== chainId) {
                    try {
                      await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: chainIdHex }],
                      });
                    } catch (switchError) {
                      // This error code indicates that the chain has not been added to MetaMask.
                      if (switchError.code === 4902) {
                        try {
                          await provider.request({
                            method: "wallet_addEthereumChain",
                            params: [
                              {
                                chainId: chainIdHex,
                                chainName,
                                rpcUrls,
                                blockExplorerUrls: [
                                  "https://testnet.arbiscan.io/",
                                ],
                              },
                            ],
                          });
                        } catch (addError) {
                          console.error(addError);
                          throw addError;
                        }
                      }
                    }
                  }
                  return provider;
                },
              },
              // torus: {
              //   package: Torus,
              //   options: {
              //     networkParams: {
              //       host: rpcUrl,
              //       chainId,
              //       networkId: chainId,
              //     },
              //   },
              // },
              "custom-rise": enabledSecKey
                ? securityKeyProvider.providerOptions(_network.rpcUrl)
                : undefined,
            },
          }
        : {
            disableInjectedProvider: true,
            cacheProvider: false,
            providerOptions: {
              "custom-rise": enabledSecKey
                ? securityKeyProvider.providerOptions(_network.rpcUrl)
                : undefined,
            },
          };
  } catch (e) {
    console.error(e);
  }
}
export default {
  init,
  isWalletOwnerOrDelegate,
  async checkCachedProvider() {
    await init();
    const web3modal = new SafeAppWeb3Modal(_web3modalOptions);
    return web3modal.cachedProvider;
  },
  async mainnetWallets() {
    const web3Modal = new SafeAppWeb3Modal({
      network: "mainnet",
    });

    let instance = await web3Modal.connect();
    const info = getProviderInfo(instance);
    instance = {
      logo: info.logo,
      type: info.name,
      provider: new ethers.providers.Web3Provider(instance, "any"),
    };
    return instance;
  },
  async wallets() {
    if (!_web3modalOptions) await init();
    const web3modal = new SafeAppWeb3Modal(_web3modalOptions);
    web3modal.clearCachedProvider();

    let instance;
    const loadedAsSafeApp = await web3modal.isSafeApp();

    try {
      if (loadedAsSafeApp) {
        instance = await web3modal.requestProvider();
      } else {
        // Security key may return null, so we loop until the user closes the web3modal, which will throw an exception
        while (!instance) instance = await web3modal.connect();

        if (
          getProviderInfo(instance).id === "walletconnect" &&
          instance.chainId !== _network.chainId
        ) {
          instance.disconnect();
          throw new Error(
            `Wrong chainId. Connected to chain id: ${instance.chainId}`
          );
        }
        if (instance.type !== "Security Key") {
          const info = getProviderInfo(instance);
          instance = {
            logo: info.logo,
            type: info.name,
            provider: new ethers.providers.Web3Provider(instance, "any"),
            instance,
          };
        }
      }

      return instance;
    } catch (e) {
      const errorMessage = e?.toString();
      if (
        e.code === 4001 ||
        errorMessage.includes("Modal closed by user") ||
        e.message === "User cancelled login"
      )
        throw "";
      else throw new Error(e);
    }
  },
  async getRPCUrl() {
    await init();
    return _network.rpcUrl;
  },
  async getNetwork() {
    await init();
    return _network;
  },
  async getChainId() {
    await init();
    return _network.chainId;
  },
  async signTypedData(provider, typedData) {
    if (!provider || !provider.getSigner) throw "Provider not connected";
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();

    const walletNotAllowedStore = useWalletNotAllowedStore();

    const sessionStore = useSessionStore();
    await sessionStore.me();
    const riseIdStore = useRiseIdStore();
    const riseIdOwner = await riseIdStore.getOwner(sessionStore.riseId);
    const delegates = await riseIdStore.getDelegates();
    const authorizedWallets = [
      riseIdOwner?.toLowerCase(),
      ...delegates.map((e) => e.address?.toLowerCase()),
    ];
    if (!authorizedWallets.includes(signerAddress?.toLowerCase())) {
      walletNotAllowedStore.setOpened(true);
      throw "Wallet not allowed to sign data for the current Rise ID.";
    }
    try {
      const account = await signer.getAddress();
      if (
        account.toLowerCase() !== riseIdOwner.toLowerCase() &&
        !delegates
          .map((e) => e.address.toLowerCase())
          .includes(account.toLowerCase())
      )
        throw "This wallet has no access to your Rise ID.";

      if (
        provider &&
        provider.provider &&
        (provider.provider.name === "MewConnect" ||
          provider.provider.isMetaMask)
      ) {
        const { domain, primaryType, types, message } = typedData;
        domain.chainId = Number(domain.chainId);
        console.log("Using RPC signer provider", {
          account,
          typedData: { domain, primaryType, types, message },
        });
        const sent = await provider.send("eth_signTypedData_v4", [
          account,
          JSON.stringify({ domain, primaryType, types, message }),
        ]);
        return sent;
      } else {
        const _types = { ...typedData.types };
        delete _types.EIP712Domain;
        console.log("Using _signTypedData signer provider", {
          account,
          typedData,
        });
        const signed = await signer._signTypedData(
          typedData.domain,
          _types,
          typedData.message
        );
        return signed;
      }
    } catch (e) {
      console.error(e);
      throw "Message was not signed";
    }
  },
};
