<script>
export default {
  props: {
    icon: {
      type: [String, Array],
      default: "house",
    },
    to: {
      type: [String, Object],
      default: "",
    },
    name: {
      type: String,
      default: "Menu item",
    },
    number: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isLink() {
      return this.to.length > 0;
    },
  },
};
</script>

<template>
  <nuxt-link
    :is="isLink ? 'router-link' : 'button'"
    exact-active-class="bg-gray-800 dark:bg-gray-800 [&>div>div]:hidden [&>div>div:nth-child(2)]:block"
    :active-class="
      ['Fund', 'Pay', 'Withdraw'].includes(name)
        ? 'bg-gray-800 dark:bg-gray-800 [&>div>div]:hidden [&>div>div:nth-child(2)]:block'
        : ''
    "
    :to="to"
    class="flex h-[36px] w-full cursor-pointer items-center rounded-lg px-[12px] py-[4px] text-white hover:bg-gray-900 dark:text-white dark:hover:bg-gray-900"
    :class="isLink ? '' : '[&>div>div:nth-child(2)]:block [&>div>div]:hidden'"
    @click="$emit('click')"
  >
    <div class="mr-4 flex h-5 w-5 items-center justify-center">
      <div class="h-5 w-5">
        <font-awesome-icon
          class="h-5 w-5 [&>*]:fill-[url(#gradient_03)]"
          :icon="icon"
        />
      </div>
      <div
        class="!visible hidden h-5 w-5"
        :class="isLink ? 'text-white' : 'text-gray-400'"
      >
        <font-awesome-icon class="h-5 w-5" :icon="icon" />
      </div>
    </div>
    <span class="text-base font-medium">
      {{ name }}
    </span>
    <div
      v-if="number > 0"
      class="ml-2 flex h-6 w-6 items-center justify-center rounded-full bg-primary-500 text-xs font-medium text-white dark:bg-primary-500 dark:text-white"
    >
      {{ number }}
    </div>
  </nuxt-link>
</template>
