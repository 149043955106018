<script>
import CoreModal from "~/components/atoms/CoreModal.vue";
import { useWalletStore } from "~/store/wallet";

export default {
  components: { CoreModal },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    wallet: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const walletStore = useWalletStore();
    return {
      walletStore,
    };
  },

  data() {
    return {
      securityKey: "",
    };
  },

  methods: {
    async unlockSecurityKey() {
      this.$loader.show();
      try {
        await this.walletStore.connectSecurityKey(this.securityKey);
        this.$emit("update:modelValue", false);
        this.$toastSuccess("Security key unlocked successfully.");
      } catch (e) {
        if (e.toString().includes("Wrong chainId")) this.$emit("wrongChainId");
        else this.$sentryCaptureException(e);
      }
      this.securityKey = "";
      this.$loader.hide();
    },
    async openWeb3Modal() {
      this.$emit("update:modelValue", false);
      try {
        await this.walletStore.connectWallet();
        this.$toastSuccess("Wallet unlocked successfully.");
      } catch (e) {
        if (e.toString().includes("Wrong chainId")) this.$emit("wrongChainId");
        else this.$sentryCaptureException(e);
      }
    },
  },
};
</script>

<template>
  <CoreModal
    hide-top-bar
    :value="modelValue"
    @update:modelValue="$emit('update:modelValue', false)"
  >
    <div class="flex min-w-[400px] flex-col">
      <div class="pb-2 text-2xl font-semibold">Security Key</div>
      <RiseTextField v-model="securityKey" type="password" />
      <RiseButton
        :disabled="securityKey.length < 10"
        class="mt-4"
        text="Connect"
        @click="unlockSecurityKey"
      />
      <div class="flex py-2">
        <div class="flex-grow">
          <div class="h-[50%] border-b border-gray-600" />
        </div>
        <div class="text-xxl px-2 text-gray-600">OR</div>
        <div class="flex-grow">
          <div class="h-[50%] border-b border-gray-600" />
        </div>
      </div>
      <RiseButton cta text="Connect with Web3" @click="openWeb3Modal()" />
    </div>
  </CoreModal>
</template>
