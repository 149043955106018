<script>
import { useIntercom } from "@homebaseai/vue3-intercom";
import Loading from "@/components/molecules/Loading.vue";
import SignDataLoading from "@/components/molecules/SignData.vue";
import ProfileButton from "~/components/molecules/ProfileButton.vue";
import LeftMenu from "~/components/organisms/LeftMenu.vue";
import WalletNotAllowedModal from "~/components/ui/molecules/WalletNotAllowedModal.vue";
import ConfirmModal from "~/components/organisms/ConfirmModal.vue";
import Web3Modal from "~/components/organisms/Web3Modal.vue";
import { mintUSDC } from "~/services/blockchain";
import { intercomHash } from "~/services/user";

import { useSessionStore } from "~~/store/session";
import { useLoaderStore } from "~~/store/loader";
import { useWalletStore } from "~~/store/wallet";
import { useLeftMenuStore } from "~~/store/leftMenu";
import { useRiseIdStore } from "~/store/riseId";

export default {
  components: {
    Loading,

    SignDataLoading,
    LeftMenu,
    ConfirmModal,
    WalletNotAllowedModal,
    ProfileButton,
    Web3Modal,
  },

  props: ["error"],
  setup() {
    const sessionStore = useSessionStore();
    const loaderStore = useLoaderStore();
    const walletStore = useWalletStore();
    const leftMenuStore = useLeftMenuStore();
    const runtimeConfig = useRuntimeConfig();
    const intercom = useIntercom();
    const riseIdStore = useRiseIdStore();
    return {
      riseIdStore,
      sessionStore,
      loaderStore,
      walletStore,
      leftMenuStore,
      runtimeConfig,
      intercom,
    };
  },
  data() {
    return {
      timeout: null,
      profileMenu: false,
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },
    loader() {
      return this.loaderStore.isLoading;
    },
    hasSignData() {
      return this.loaderStore.hasSignData;
    },
    company() {
      return this.sessionStore.company;
    },
    role() {
      return this.sessionStore.role;
    },
    walletAddress() {
      return this.walletStore.walletAddress;
    },
    walletType() {
      return this.walletStore.walletType;
    },
    riseId() {
      return this.sessionStore.riseId;
    },
    pages() {
      return {
        dashboard: "Dashboard",
        referral: "Referrals",
        tasks: "Action Items",
        documents: "Documents",
        talent: !this.company
          ? "Talent"
          : this.company.team
          ? `${this.company.team} (${this.company.name})`
          : this.company.name,
        "talent-id-payschedule": "New Pay Schedule",
        "talent-import": "Talent Import",
        "talent-id": "Talent",
        clients: "Clients",
        "payments-transaction-history": "Activity",
        "payments-schedules": "Pay Schedules",
        "payments-management": "Pay",
        "payments-batch": "Pay",
        "payments-batch-csv": "Pay",
        "payments-timesheets": "Time Entry List",
        "payments-timesheets-detail": "New Time Entry",
        payments: "Pay",
        "payments-userId": "Pay",
        "payments-userId-instant": "Pay",
        "payments-userId-once": "Pay",
        "payments-userId-hourly": "Pay",
        "payments-userId-milestone": "Pay",
        "payments-userId-recurring": "Pay",
        "management-company": "Your Teams",
        "management-company-id": "My Team",
        "management-company-create": "Create New...",
        "management-company-create-team": "Create a Team Related to...",
        "clients-id": "Team",
        account: "Account",
        developer: "Developer Tools",
        "clients-id-classification": "Worker Classification",
        "profile-wallet-fund": "Fund Account",
        "profile-wallet-fund-credit-card": "Fund Account - Credit Card",
        "profile-wallet-fund-crypto": "Fund Account - Crypto",
        invoices: "Invoices",
        "invoices-add": "Invoices",
        "invoices-add-id": "Invoices",
        fund: "Deposit Funds",
        "fund-deposit": "Deposit Funds",
        "fund-deposit-us": "Deposit Funds",
        "fund-riseid": "Deposit Funds",
        "fund-external-account-accountId": "Deposit Funds",
        "fund-dedicated-address-accountId": "Deposit Funds",
        "fund-wire-accountId": "Deposit Funds",
        "accounts-add-funding": {
          client: "Add External Account",
          contractor: "Withdraw Options",
        },
        "accounts-add-funding-options": {
          client: "Add External Account",
          contractor: "Add Withdraw Option",
        },
        "accounts-add-funding-options-wire-transfer": {
          client: "Add External Account",
          contractor: "Add Withdraw Option",
        },
        "accounts-add-withdraw-options-brla": "Add Withdraw Option",
        "accounts-add-withdraw-ramps": "Add Withdraw Option",
        "accounts-add-withdraw-options-international": "Add Withdraw Option",
        "accounts-add-withdraw-options-international_bank":
          "Add Withdraw Option",
        "accounts-add-withdraw-options-eur": "Add Withdraw Option",
        "accounts-add-withdraw-options-kraken": "Add Withdraw Option",
        "accounts-add-withdraw-options-binance": "Add Withdraw Option",
        "accounts-add-withdraw-options-ngn": "Add Withdraw Option",
        "accounts-add-withdraw-options-gbp": "Add Withdraw Option",
        "accounts-add-withdraw-options-us": "Add Withdraw Option",
        "accounts-add-withdraw-options-coinbase": "Add Withdraw Option",
        "accounts-add-withdraw-options-forex": "Add Withdraw Option",
        "accounts-add-funding-options-blockchain-wallet": {
          client: "Add External Account",
          contractor: "Add Withdraw Option",
        },
        "accounts-add-funding-options-dedicated-address": {
          client: "Add External Account",
          contractor: "Add Withdraw Option",
        },
        "talent-invite": "Invite",
        "talent-invite-direct": "Invite",
        "talent-invite-warmed": "Invite",
        "talent-invite-batch_warmed": "Invite",
        "talent-invite-batch_warmed_light": "Invite",
        "talent-invite-warmed_light": "Invite",
        "talent-invite-sumsub": "Invite",
        withdraw: "Withdraw Funds",
        "withdraw-wire-accountId": "Withdraw Funds",
        "withdraw-blockchain-accountId": "Withdraw Funds",
        "accounts-add-withdraw": "Add External Account",
        "accounts-add-withdraw-options": "Add External Account",
        "accounts-add-withdraw-options-wire-transfer": "Add External Account",
        "accounts-add-withdraw-options-blockchain-wallet":
          "Add External Account",
        "accounts-add-withdraw-options-coinbase_wallet": "Add External Account",
        "accounts-add-withdraw-options-blockchain-wallet-network":
          "Add External Account",
        "accounts-add": {
          client: "Add External Account",
          contractor: "Withdraw Options",
        },
        "accounts-add-arbitrum": {
          client: "Add External Account",
          contractor: "Add Withdraw Option",
        },
        "accounts-add-arbitrum-token": {
          client: "Add External Account",
          contractor: "Add Withdraw Option",
        },
        "accounts-add-mainnet": {
          client: "Add External Account",
          contractor: "Add Withdraw Option",
        },
        "accounts-add-mainnet-token": {
          client: "Add External Account",
          contractor: "Add Withdraw Option",
        },
        "accounts-add-blockchain-wallet": {
          client: "Add External Account",
          contractor: "Add Withdraw Option",
        },
        "accounts-add-wire": "Add Bank Wire",
        "accounts-add-circle-wire": "Add Bank Wire",
        "accounts-add-ach": "Add Bank ACH",
        "accounts-add-withdraw-usdc": "USDC on Arbitrum Withdraw Account",
        "accounts-add-withdraw-usdc-mainnet":
          "Ethereum Mainnet Withdraw Account",
        "accounts-add-withdraw-token": "Token on Arbitrum Withdraw Account",
        "withdraw-eth-address-accountId": "Withdraw Token to Arbitrum ",
        "riseid-erc20-withdraw": "Rise ID ERC20 Withdraw",
        "withdraw-eth-address-mainnet-accountId":
          "Withdraw to Ethereum Mainnet",
        "withdraw-circle-ach-accountId": "ACH Withdraw",
        "withdraw-bank-account-wire-accountId": "Wire Withdraw",
        "payments-timesheets-psId": "Time Entry",
        riseid: "Rise ID",
        "subscription-options": "Subscription Plan",
        subscription: "Subscription",
        profile: "Profile",
        security: "Security",
      };
    },
    pageTitle() {
      const page = this.pages[this.$route.name];
      if (typeof page === "object") return page[this.role];
      return page;
    },
    NODE_ENV() {
      return this.runtimeConfig.public.NODE_ENV;
    },
  },
  watch: {
    $route() {
      this.leftMenuStore.setMobileOpen(false);
    },
  },
  async mounted() {
    this.riseIdStore.getInfo();
    const me = await this.sessionStore.me();
    if (
      this.NODE_ENV === "production" &&
      this.runtimeConfig.public.INTERCOM_APP_ID
    ) {
      try {
        const { hash } = await intercomHash();
        this.intercom.boot({
          app_id: this.runtimeConfig.public.INTERCOM_APP_ID,
          user_id: me.id,
          name: `${me.firstname} ${me.lastname}`,
          email: me.email,
          user_hash: hash,
        });
      } catch (e) {
        console.error(e);
      }
    }

    if (this.runtimeConfig.public.isLocalDevelopment) return;
    if (me.email) this.$sentrySetUser({ email: me.email });
    if (me.role) this.$sentrySetTag("role", me.role.name);
    if (this.error == null) return;

    if (
      this.error.statusCode === 500 &&
      /^Loading chunk [0-9]+ failed/.test(this.error.message) &&
      window.location.hash !== "#retry"
    ) {
      window.location.hash = "#retry";
      window.location.reload(true);
    }
  },
  methods: {
    async mintTestUSDC() {
      this.$loader.show();
      try {
        // eslint-disable-next-line no-alert
        const wallet = prompt("Insert the wallet address");
        if (wallet && /^0x[a-fA-F0-9]{40}$/.test(`${wallet}`)) {
          const tx = await mintUSDC(wallet);
          this.$toastSuccess("Minted 10,000 USDC");
          console.log("Minted USDC", tx);
          this.$router.push("/fund/riseid");
        }
      } catch (e) {
        this.$sentryCaptureException(e);
      }
      this.$loader.hide();
    },
  },
};
</script>

<template>
  <div class="h-screen min-h-screen flex flex-col overflow-hidden">
    <!-- <div
      class="w-full py-1 text-xs bg-yellow-700 text-white font-semibold text-center"
    >
      The site will be undergoing maintenance at 11 AM ET for 30 minutes.
    </div> -->
    <div class="flex-1 bg-gray-50 font-serif w-full overflow-y-auto">
      <Web3Modal />
      <RiseGradients />
      <div
        class="h-full w-full overflow-hidden pt-20 md:grid md:grid-cols-[280px_minmax(0,_1fr)] md:grid-rows-[_1fr] md:pt-0 print:!p-0"
      >
        <LeftMenu />
        <main v-if="user" class="flex h-full w-full flex-col overflow-y-auto">
          <div
            v-if="pageTitle"
            class="flex w-full items-center border-gray-200 bg-dashboard-bg p-5 pt-0 md:border-b md:bg-gray-50 md:px-8 md:py-5"
          >
            <h1
              class="flex-1 text-left text-3xl font-bold text-white md:text-4xl md:text-gray-800"
            >
              {{ pageTitle }}
            </h1>
            <div
              v-if="role === 'client'"
              class="hidden items-center gap-3 md:flex"
            >
              <nuxt-link to="/payments" active-class="hidden">
                <RiseButton
                  type="submit"
                  text="Quick pay"
                  variant="primary"
                  size="sm"
                />
              </nuxt-link>
              <nuxt-link to="/fund" active-class="hidden">
                <RiseButton
                  type="submit"
                  text="Fund account"
                  variant="outline-gradient"
                  size="sm"
                />
              </nuxt-link>
              <nuxt-link to="/talent/invite/direct">
                <RiseButton
                  type="submit"
                  text="Invite team"
                  variant="outline-gradient"
                  size="sm"
                />
              </nuxt-link>
            </div>
            <RiseButton
              v-if="
                ['local', 'staging', 'development', 'dev'].includes(NODE_ENV)
              "
              text="Mint USDC"
              variant="outline-gradient"
              size="sm"
              class="ml-3"
              @click="mintTestUSDC()"
            />
            <nuxt-link
              to="/referral"
              active-class="hidden"
              class="ml-3 hidden md:block"
            >
              <RiseButton
                v-if="role === 'client'"
                text="Referral Program"
                variant="outline-gradient"
                size="sm"
              />
            </nuxt-link>
            <div class="hidden md:block">
              <ProfileButton />
            </div>
          </div>
          <div
            class="w-full flex-1 p-5 text-gray-800 md:px-8 md:py-5 print:!p-0"
          >
            <slot />
          </div>
        </main>
      </div>
      <SignDataLoading />
      <Loading v-if="loader && !hasSignData" />
      <ConfirmModal />
      <WalletNotAllowedModal />
    </div>
  </div>
</template>

<style>
/* swipe-right */
.swipe-right-leave-active,
.swipe-right-enter-active {
  transition: all 0.1s ease-in-out;
}

.swipe-right-enter {
  opacity: 0;
  transform: translate(-100%, 0);
}

.swipe-right-enter-to,
.swipe-right-leave {
  opacity: 1;
  transform: translate(0, 0);
}

.swipe-right-leave-to {
  opacity: 0;
  transform: translate(100%, 0);
}
</style>
