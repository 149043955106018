<template>
  <div class="h-0 w-0">
    <svg width="0" height="0">
      <linearGradient id="gradient_02" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0" stop-color="#5E59EC" />
        <stop offset="1" stop-color="#1F2B38" />
      </linearGradient>
    </svg>
    <svg width="0" height="0">
      <linearGradient id="gradient_03" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0" stop-color="#3CA4E0" />
        <stop offset="0.806815" stop-color="#4E5FD7" />
        <stop offset="1" stop-color="#4E62D8" />
      </linearGradient>
    </svg>
    <svg width="0" height="0">
      <linearGradient id="gradient_13" x1="0%" y1="0%" x2="100%" y2="2.86642%">
        <stop offset="0" stop-color="#AF93E7" />
        <stop offset="1" stop-color="#412789" />
      </linearGradient>
    </svg>
  </div>
</template>
