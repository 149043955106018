<script>
export default {
  props: {
    address: {
      type: String,
      default: "",
    },
    copyable: {
      type: Boolean,
      default: false,
    },
    network: {
      type: String,
      default: "arbitrum",
    },
    color: {
      type: String,
      default: "gray-400",
    },
  },
  computed: {
    fullAddress() {
      return `${this.$filters.walletNetwork(
        this.network
      )}${this.$filters.walletAddress(this.address)}`;
    },
  },

  methods: {
    copy() {
      if (this.copyable) {
        navigator.clipboard.writeText(
          `${this.$filters.walletNetwork(this.network)}${this.address}`
        );
        this.$toastSuccess("Address copied successfully.");
      }
    },
  },
};
</script>

<template>
  <div
    class="flex items-center self-center"
    :class="[{ 'cursor-pointer': copyable }, `text-${color}`]"
    @click="copy()"
  >
    <div
      v-if="address === 'pending'"
      class="min-w-0 flex-1 truncate text-xs font-medium"
    >
      Pending...
    </div>
    <div v-if="address" class="min-w-0 flex-1 truncate text-xs font-medium">
      {{ fullAddress }}
    </div>
    <font-awesome-icon
      v-if="copyable"
      icon="copy"
      class="flex-0 ml-3 !h-4 !w-4"
    />
  </div>
</template>

<style></style>
