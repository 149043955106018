<script>
import CoreModal from "~/components/atoms/CoreModal.vue";
import walletsService from "~/services/wallets";

export default {
  components: { CoreModal },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      network: {
        rpcUrl: "",
        chainId: "",
        chainName: "",
      },
    };
  },

  async created() {
    const res = await walletsService.getNetwork();
    if (res) this.network = res;
  },
};
</script>

<template>
  <CoreModal
    hide-top-bar
    :value="modelValue"
    @update:modelValue="$emit('update:modelValue', false)"
  >
    <div class="flex max-w-[600px] flex-col text-gray-500">
      <div class="self-center pb-8">
        <font-awesome-icon
          class="text-4xl text-yellow-600"
          :icon="['fas', 'exclamation-triangle']"
        />
      </div>
      <div class="text-center text-lg">
        The wallet that you tried to connect is not connected to the correct
        chain, you need to connect it to {{ network.chainName }} network:
      </div>
      <div class="self-center py-4">
        <div><strong>RPC:</strong> {{ network.rpcUrl }}</div>
        <div><strong>Chain ID:</strong> {{ network.chainId }}</div>
        <div><strong>Chain Name:</strong> {{ network.chainName }}</div>
      </div>
      <RiseButton text="Close" @click="$emit('update:modelValue', false)" />
    </div>
  </CoreModal>
</template>
