import { Signer, ethers } from "ethers";
import riseSecurityKey from "~/services/riseSecurityKey";
import { useNuxtApp } from "#app";

class SecurityKeySigner extends Signer {
  constructor(provider, address) {
    super();
    this.provider = provider;
    this.address = address;
  }

  getAddress() {
    return this.address;
  }

  listAccounts() {
    return [this.address];
  }

  signMessage(message) {
    return riseSecurityKey.signMessage(message);
  }

  signTransaction(transaction) {
    return riseSecurityKey.signTransaction(transaction);
  }

  _signTypedData(domain, types, value) {
    return riseSecurityKey._signTypedData({ domain, types, value });
  }
}

async function prompt() {
  const nuxtApp = useNuxtApp();
  const app = nuxtApp.vueApp;
  const jwt = await app.config.globalProperties.$clerk.session.getToken({
    template: "User_Template",
  });

  return new Promise((resolve, reject) => {
    // Create Overlay
    const overlay = document.getElementById("rsk-modal");
    overlay.classList.remove("hidden");
    const iframe = document.getElementById("rsk-iframe");
    iframe.contentWindow.postMessage(
      { type: "riseAuthToken", value: `Bearer ${jwt}` },
      "*"
    );
    const listener = window.addEventListener("message", ({ data }) => {
      if (data.type === "securityKey") {
        window.removeEventListener("message", listener);
        overlay.classList.add("hidden");
        resolve(data.payload);
      } else if (data.type === "securityKey_wrongPassword") {
        window.removeEventListener("message", listener);
        overlay.classList.add("hidden");
        reject(new Error("Wrong Password"));
      }
    });
    const clickListener = overlay.addEventListener("click", () => {
      window.removeEventListener("message", listener);
      overlay.classList.add("hidden");
      overlay.removeEventListener("click", clickListener);
      reject(new Error("Modal closed by user"));
    });
  });
}

function destroyPrompt() {
  document.getElementsByClassName("web3securitykeymodal")[0]?.remove();
}

function providerOptions(rpc) {
  return {
    display: {
      logo: "/logo/rise-symbol.png",
      name: "Rise Security Key",
      description: "Connect to your Rise Hosted wallet",
    },
    package: riseSecurityKey,
    options: { rpc },
    connector: async (_riseSecurityKeyService, options) => {
      const address = await prompt();
      const provider = ethers.getDefaultProvider(options.rpc);
      const wallet = new SecurityKeySigner(provider, address);
      provider.listAccounts = () => [wallet.address];
      provider.getSigner = () => wallet;
      return { type: "Security Key", wallet, provider };
    },
  };
}

export default {
  prompt,
  destroyPrompt,
  providerOptions,
};
