<script>
import RiseModal from "~/components/ui/atoms/Modal.vue";
import { PROVIDER_LOGOS } from "~/utils/constants";
import { useWalletNotAllowedStore } from "~/store/walletNotAllowed";
import { useRiseIdStore } from "~/store/riseId";
import { useWalletStore } from "~/store/wallet";

export default {
  components: {
    RiseModal,
  },
  setup() {
    const walletNotAllowedStore = useWalletNotAllowedStore();
    const riseIdStore = useRiseIdStore();
    const walletStore = useWalletStore();
    return {
      walletNotAllowedStore,
      riseIdStore,
      walletStore,
    };
  },
  computed: {
    hasWalletConnected() {
      return this.walletStore.isActive;
    },
    isWrongWallet() {
      return ![this.riseIdStore.owner, ...this.riseIdStore.delegates].includes(
        this.walletStore.walletAddress
      );
    },
    opened() {
      return this.walletNotAllowedStore.isOpened;
    },
    wallets() {
      const addresses = [this.riseIdStore.owner, ...this.riseIdStore.delegates];

      return addresses.map((address, i) => {
        const seen = this.riseIdStore.wallets.find(
          (wallet) => wallet.wallet_address === address
        );
        return {
          wallet_address: address,
          wallet_type: seen?.wallet_type || "Other",
        };
      });
    },
    walletAddress() {
      return this.walletStore.walletAddress;
    },
  },
  methods: {
    getLogo(provider) {
      return PROVIDER_LOGOS[provider?.toLowerCase()] || "/icons/other.svg";
    },
    setOpened(value) {
      this.walletNotAllowedStore.isOpened = value;
    },
    disconnectWallet() {
      this.walletStore.DISCONNECT();
    },
  },
};
</script>

<template>
  <RiseModal
    v-if="hasWalletConnected && isWrongWallet"
    v-model="opened"
    variant="secondary"
    hide-actions
    title="Connected wallet not allowed"
    @update:modelValue="setOpened"
    @confirm="setOpened(false)"
  >
    <p class="mb-6 text-base font-normal">
      It seems your connected wallet is not an owner or delegate, please
      disconnect your wallet and connect with some listed below.
    </p>
    <div v-if="wallets" class="mb-6 grid gap-4 md:grid-cols-2">
      <div
        v-for="(wallet, i) in wallets"
        :key="i"
        class="flex items-center gap-4"
      >
        <div
          class="flex h-[60px] min-w-[60px] items-center justify-center rounded-lg bg-white"
        >
          <img
            :src="getLogo(wallet.wallet_type)"
            alt="Wallet Type"
            class="h-[36px] w-auto"
          />
        </div>
        <div class="flex-1">
          <div class="mb-1 text-base font-bold capitalize">
            {{ wallet.wallet_type }} <span v-if="i === 0">(Owner)</span>
          </div>
          <div class="text-base font-normal">
            ({{ $filters.walletAddress(wallet.wallet_address) }})
          </div>
        </div>
      </div>
    </div>
    <div class="mb-7 text-base font-normal">
      Connected wallet:
      <span class="font-semibold">
        ({{ $filters.walletAddress(walletAddress) }})
      </span>
    </div>
    <div class="flex justify-end gap-2">
      <!-- <RiseButton
        text="Cancel"
        variant="outline-white"
        @click="setOpened(false)"
      /> -->
      <RiseButton text="Disconnect" @click="disconnectWallet" />
    </div>
  </RiseModal>
</template>
