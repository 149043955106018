<script>
export default {
  data() {
    return {
      params: null,
      show: false,
    };
  },

  computed: {
    description() {
      return this.params?.description
        ? this.params.description
        : "Are you sure you want to proceed ?";
    },
    icon() {
      return this.params?.icon
        ? this.params.icon
        : {
            color: "yellow-600",
            name: "exclamation-triangle",
          };
    },
  },

  created() {
    // TODO: Listen to the event in a 'Vue 3' way
    // this.$root.$on('openConfirmModal', this.open)
  },

  methods: {
    open(params) {
      this.params = params;
      this.show = true;
    },
    close(value) {
      this.$confirm.hide(value);
      this.show = false;
    },
    waitForConfirmation() {
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
  },
};
</script>

<template>
  <div
    v-if="show"
    class="fixed flex h-screen w-screen items-center justify-center bg-gray-500/50"
    @mousedown="close(false)"
  >
    <div
      class="flex max-w-[600px] flex-col items-center gap-4 rounded-md bg-white px-8 py-4"
      @mousedown.stop
    >
      <font-awesome-icon
        :class="`text-${icon.color} text-4xl`"
        :icon="['fas', 'exclamation-triangle']"
      />
      <div class="text-center text-xl text-gray-600">
        {{ description }}
      </div>

      <div class="flex gap-4">
        <RiseButton gray @click="close(false)"> Cancel </RiseButton>
        <RiseButton cta @click="close(true)"> Confirm </RiseButton>
      </div>
    </div>
  </div>
</template>
