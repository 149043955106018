import { defineStore } from "pinia";

export const useLeftMenuStore = defineStore("leftMenu", {
  state: () => ({
    isMobileOpen: false,
  }),

  actions: {
    setMobileOpen(value) {
      this.isMobileOpen = value;
    },
  },
});
