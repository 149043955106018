<script setup>
import { useWalletStore } from "~/store/wallet";

const walletStore = useWalletStore();
const runtimeConfig = useRuntimeConfig();

const web3modal_iframe = ref(null);

const toast = inject("toast");

onMounted(() => {
  // child.value will hold an instance of <Child />
  window.addEventListener("message", (event) => {
    if (event.data?.type === "securityKey_wrongPassword") {
      toast.error(
        event.data.payload?.error
          ? event.data.payload.error
          : event.data.message
      );
    }
  });
});
</script>

<template>
  <iframe
    id="web3modal_iframe"
    ref="web3modal_iframe"
    :src="runtimeConfig.public.CONNECTOR_URL"
    class="fixed z-50 hidden h-screen w-screen bg-gray-200/25"
    @load="walletStore.getAccount"
  />
</template>
