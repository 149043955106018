<script>
import CoreModal from "~/components/atoms/CoreModal.vue";
import { useSessionStore } from "~~/store/session";
import { useWalletStore } from "~~/store/wallet";
import { useLoaderStore } from "~~/store/loader";

export default {
  components: {
    CoreModal,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const sessionStore = useSessionStore();
    const walletStore = useWalletStore();
    const loaderStore = useLoaderStore();
    return {
      sessionStore,
      walletStore,
      loaderStore,
    };
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    opened() {
      return this.loaderStore.hasSignData;
    },
    riseId() {
      return this.sessionStore.riseId;
    },
    walletAddress() {
      return this.walletStore.walletAddress;
    },
    rawSignData() {
      return this.loaderStore.signData;
    },
    signData() {
      if (this.rawSignData && this.rawSignData.contract.includes("Forwarder")) {
        const s =
          this.rawSignData.params &&
          this.rawSignData.params?.find((s) => s._isSignData);
        if (s) return s;
      }
      return this.rawSignData;
    },
  },
  methods: {
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<template>
  <CoreModal
    :model-value="opened"
    :closeable="false"
    card-classes="max-w-[600px]"
    hide-top-bar
    overflowed
  >
    <div class="space-y-4 text-gray-600">
      <div class="mx-auto">
        <div class="mx-auto mb-4 h-[256px] w-[256px]">
          <lottie-animation
            animation-link="/lottie/cpu-processing-loader.json"
          />
        </div>
        <div class="text-center text-4xl font-bold">Processing</div>
        <div
          class="hidden cursor-pointer text-center text-sm text-blue-600 underline md:block"
          @click="expand"
        >
          {{ expanded ? "hide" : "view" }} details
        </div>
      </div>
      <div v-show="expanded" class="hidden md:block">
        <div class="text-3xl font-semibold">Transaction Details</div>
        <p class="max-w-[400px] font-light">
          Your transaction is being submitted to a blockchain, the underpinnings
          of Rise which makes us more secure than other payment platforms. You
          can learn more about blockchain transactions here.
        </p>
        <div v-if="signData" class="mt-4 rounded-xl border-4 p-4">
          <div>
            <div>
              <span class="font-bold">to:</span>
              {{ signData.contract }}
            </div>
            <div class="text-xs">({{ signData.contractAddress }})</div>
          </div>
          <div class="mt-2">
            <div>
              <span class="font-bold">from:</span>
              Owner
            </div>
            <div class="text-xs">
              ({{ signData.from || walletAddress || riseId }})
            </div>
          </div>
          <div class="mt-2">
            <div>
              <span class="font-bold">method:</span>
              {{ signData.functionIdentifier }}
            </div>
            <div class="text-xs">({{ signData.functionSignature }})</div>
            <div v-if="signData.params && signData.params.length" class="pl-4">
              <div
                v-for="param in signData.params"
                :key="param.name"
                class="text-xs"
              >
                <span class="font-bold">{{ param.name }}:</span>
                {{
                  param.value && param.value._isSignData
                    ? `${param.value.contract} ${param.value.functionIdentifier}`
                    : param.value
                }}
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div>
              <span class="font-bold">methodSignature:</span>
              {{ signData.functionSignature }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </CoreModal>
</template>
