<script>
import { useSessionStore } from "~/store/session";

export default {
  setup() {
    const sessionStore = useSessionStore();
    return {
      sessionStore,
    };
  },
  data() {
    return {
      profileMenu: false,
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },
    role() {
      return this.sessionStore.role;
    },
  },
};
</script>

<template>
  <div>
    <div
      v-if="user"
      class="relative ml-3 h-10 w-10 cursor-pointer rounded-full border-2 border-white bg-cover bg-center bg-no-repeat hover:border-primary-700"
      :style="`background-image: url(${
        user.avatar || user.providerAvatar || user.gravatar
      });`"
      @click="profileMenu = !profileMenu"
    >
      <div
        v-if="profileMenu"
        class="absolute right-0 top-[calc(100%+8px)] z-20 animate-fadeIn_fast rounded-lg bg-gray-900 px-2 py-2 text-sm font-medium text-white transition-all"
        @mouseleave="profileMenu = false"
      >
        <nuxt-link
          class="mb-2 flex cursor-pointer items-center rounded-lg p-2 last:mb-0 hover:bg-gray-800"
          to="/profile"
        >
          <font-awesome-icon
            class="flex-0 mr-3 !h-[14px] !w-[14px]"
            icon="circle-user"
          />
          <div class="min-w-0 flex-1 truncate">Profile</div>
        </nuxt-link>
        <nuxt-link
          class="mb-2 flex cursor-pointer items-center rounded-lg p-2 last:mb-0 hover:bg-gray-800"
          to="/account"
        >
          <font-awesome-icon
            class="flex-0 mr-3 !h-[14px] !w-[14px]"
            icon="cog"
          />
          <div class="min-w-0 flex-1 truncate">Account</div>
        </nuxt-link>
        <nuxt-link
          class="mb-2 flex cursor-pointer items-center rounded-lg p-2 last:mb-0 hover:bg-gray-800"
          to="/security"
        >
          <font-awesome-icon
            class="flex-0 mr-3 !h-[14px] !w-[14px]"
            icon="key"
          />
          <div class="min-w-0 flex-1 truncate">Security</div>
        </nuxt-link>
        <nuxt-link
          v-if="role === 'client'"
          class="mb-2 flex cursor-pointer items-center rounded-lg p-2 last:mb-0 hover:bg-gray-800"
          to="/subscription"
        >
          <font-awesome-icon
            class="flex-0 mr-3 !h-[14px] !w-[14px]"
            icon="rocket"
          />
          <div class="min-w-0 flex-1 truncate">Subscription</div>
        </nuxt-link>
        <nuxt-link
          v-if="role === 'client'"
          class="mb-2 flex cursor-pointer items-center rounded-lg p-2 last:mb-0 hover:bg-gray-800"
          to="/developer"
        >
          <font-awesome-icon
            class="flex-0 mr-3 !h-[14px] !w-[14px]"
            icon="code"
          />
          <div class="min-w-0 flex-1 truncate">Developer</div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>
