import { defineStore } from "pinia";

export const useThemeStore = defineStore("theme", {
  state: () => ({
    theme: "light",
  }),

  getters: {
    isDark(state) {
      return state.theme === "dark";
    },
  },

  actions: {
    setTheme(value) {
      if (value === "light") document.documentElement.classList.remove("dark");
      if (value === "dark") document.documentElement.classList.add("dark");
      localStorage.setItem("theme", value);
      this.theme = value;
    },
  },
});
